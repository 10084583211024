import { ValidateAddressPayload } from '../../TYPES/api.body';
import { GET_SELLER_DELIVERIES, GET_SELLER_DELIVERY_ANALYTICS, VALIDATE_ADDRESS_DELIVERY } from '../config/endpoints';
import api from '../config/setAuthHeader';

export const getSellerDeliveries = async ({ queryKey }: any) => {
  const [_, payload] = queryKey;
  const { page, size } = payload;
  const { data } = await api.get(`${GET_SELLER_DELIVERIES}?page=${page}&pageSize=${size}`);
  return data;
};

export const getSellerDeliveriesAnalytics = async () => {
  const { data } = await api.get(`${GET_SELLER_DELIVERY_ANALYTICS}`);
  return data;
};

export const validateAddressDeliveryCallback = async (opts: ValidateAddressPayload) => {
  const { data } = await api.post(VALIDATE_ADDRESS_DELIVERY, opts);
  return data;
};
