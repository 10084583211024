export const JOIN_WAITING_LIST = '/api/v1/auth/customer-email';
// Auth
export const LOGIN = '/api/v1/seller/auth/login';
export const REGISTER = '/api/v1/seller/auth/register';
export const VERIFY_OTP = 'api/v1/seller/auth/verify';
export const REQUEST_OTP = `/api/v1/seller/auth/resend-otp`;
export const RESET_PASSWORD = '/api/v1/customer/auth/reset-password';
export const FORGOT_PASSWORD = '/api/v1/seller/auth/reset-password';
export const CONFIRM_PASSWORD_RESET = '/api/v1/seller/auth/confirm-password-reset';
export const NEW_PASSWORD = '/api/v1/customer/auth/new-password';
export const LOGOUT = '/api/v1/auth/logout';
// Profile
export const GET_CUSTOMER = '/api/v1/customer/get_customer';
export const UPDATE_CUSTOMER = '/api/v1/customer/update/1';
export const DELETE_CUSTOMER = '/api/v1/customer/1';
export const DELETE_ACCOUNT = '/api/v1/vendor/delete-account?password=';
export const CHANGE_PASSWORD = '/api/v1/vendor/change-password';
export const UPDATE_PIC = '/api/v1/customer/update_profile_picture/1';
export const GET_PROFILE = '/api/v1/vendor/get-details';
// Address
export const GET_ALL_ADDRESSES = '/api/v1/customer/addresses';
export const ADD_ADDRESS = '/api/v1/customer/add_address';
export const DELETE_ADDRESS = '/api/v1/customer/address/';
export const GET_SINGLE_ADDRESS = '/api/v1/customer/address/';
export const UPDATE_ADDRESS = '/api/v1/customer/address/update/';
export const SET_DEFAULT_ADDRESS = '/api/v1/customer/address/set_default_address/';
// product or goods
export const GET_ALL_PRODUCT = '/api/v1/customer/fetch_all_product';
export const MANUAL_PRODUCT_UPLOAD = '/api/v1/seller/product/manual/store';
export const DELETE_PRODUCT = '/api/v1/seller/product/delete';
export const UPDATE_PRODUCT = '/api/v1/seller/product/update';
export const PRODUCT_DETAILS = '/api/v1/seller/product/get-product';
//categories
export const GET_ALL_CATEGORIES = '/api/v1/unauthorized/all-categories';
export const GET_SUB_CATEGORIES = 'api/v1/categories/subcategories';
export const CHANGE_CATEGORY = '/api/v1/seller/category/change-category';
export const UPDATE_SUBCATEGORY = 'api/v1/seller/category/update_subcategory';
export const ADD_SUBCATEGORY = 'api/v1/seller/category/add_subcategory';
// Seller
export const GET_SELLER_PRODUCTS_ANALYTICS = '/api/v1/seller/overview/product_analytics';
export const GET_SELLER_PRODUCTS = '/api/v1/seller/product/seller_products';
export const GET_SELLER_USERS = '/api/v1/seller/user';
export const GET_SELLER_WALLET_BALANCE = '/api/v1/vendor/wallet/balance';
export const GET_SELLER_WALLET_POINTS = '/api/v1/vendor/wallet/points';
export const GET_SELLER_WALLET_HISTORIES = '/api/v1/seller/wallet/histories';
export const FUND_WALLET = '/api/v1/vendor/wallet/fund-wallet';
export const INITIATE_WALLET_WITHDRAWAL = '/api/v1/vendor/wallet/send-withdrawal-otp';
export const CONFIRM_WALLET_WITHDRAWAL = '/api/v1/vendor/wallet/withdraw';
export const GET_SELLER_ROLES = '/api/v1/seller/role/roles';
export const DELETE_SELLER_PRODUCT = '/api/v1/seller/product/delete';
export const GET_LOCATION = '/api/v1/seller/role/locations';
export const ADD_USER = '/api/v1/seller/user/register';
export const CHANGE_USER_ROLE = '/api/v1/seller/user/change_role';
export const DELETE_SUB_ADMIN_USER = '/api/v1/seller/user/remove_admin';
export const UPLOAD_CSV_PRODUCTS = '/api/v1/seller/product/cvs_upload/store';
export const GET_SELLER_ORDERS = '/api/v1/seller/order/view_order/all';
export const GET_SELLER_ORDERS_AS_CSV = '/api/v1/vendor/export-order-to-csv';
export const GET_SELLER_ORDER_ANALYTICS = '/api/v1/seller/order/analytics';
export const GET_SELLER_DASHBOARD_OVERVIEW = '/api/v1/seller/overview/analytics';
export const GET_SELLER_OVERVIEW_ORDERS = '/api/v1/seller/overview/';
export const UPLOAD_SELLER_LOGO = '/api/v1/vendor/upload-logo';
export const UPDATE_PROFILE = '/api/v1/vendor/update-profile';
export const ADD_BANK_DETAILS = '/api/v1/vendor/wallet/add-account';
export const GET_BANK_DETAILS = '/api/v1/vendor/wallet/account-details';
export const DELETE_BANK_DETAILS = '/api/v1/vendor/wallet/';
export const POINT_NAIRA_EQUIVALENT = '/api/v1/vendor/wallet/point-naira-equivalent?point=1';
export const CONVERT_POINTS_TO_NAIRA = '/api/v1/vendor/wallet/convert_point';

export const GET_SELLER_ORDER_STATISTICS = '/api/v1/vendor/orders-report';
export const GET_SELLER_SALES_STATISTICS = '/api/v1/vendor/sales-report';
export const GET_SELLER_TOTAL_PROFITS_STATISTICS = '/api/v1/vendor/total-profits';

// Seller Deliveries
export const GET_SELLER_DELIVERIES = '/api/v1/seller/delivery/view_delivery/all';
export const GET_SELLER_DELIVERY_ANALYTICS = '/api/v1/seller/delivery/analytics';
export const VALIDATE_ADDRESS_DELIVERY = '/api/v1/unauthorized/validate-address';

// Admin
export const ADMIN_LOGIN = '/api/v1/admin/auth/login';
//Categories
export const GET_CATEGORIES = '/api/v1/categories';
export const ADD_CATEGORY = '/api/v1/admin/category/add';
export const ADMIN_DELETE_CATEGORY = '/api/v1/admin/category/delete_category';
export const ADMIN_UPDATE_CATEGORY = '/admin/category/update';
//Admin Product
export const GET_ADMIN_PRODUCTS = 'api/v1/admin/product/all';
export const ADMIN_MANUAL_PRODUCT_UPLOAD = '/api/v1/admin/product/manual/store';
export const ADMIN_DELETE_PRODUCT = '/api/v1/admin/product/delete';
export const ADMIN_UPDATE_PRODUCT = '/api/v1/admin/product/update';

//Admin orders
export const GET_ADMIN_ORDERS = '/api/v1/admin/order/view_order/ALL';
export const GET_ADMIN_USERS = '/api/v1/admin/user/all';
export const GET_ADMIN_ORDER_ANALYTICS = '/api/v1/admin/order/analytics';
export const GET_ADMIN_ORDER_DETAILS = '/api/v1/admin/order/view_single_order';
export const ADMIN_CANCEL_ORDER = '/api/v1/admin/order/cancel_order';
export const ADMIN_TRACK_STATUS = '/api/v1/admin/order/change_order_status';

// Admin users
export const GET_SHOPPERS = '/api/v1/admin/user/shoppers';
export const GET_SELLERS = '/api/v1/admin/user/sellers';
export const ADMIN_ADD_USER = '/api/v1/admin/user/register';
export const GET_BUSINESS = '/api/v1/admin/user/businesses';
export const ADMIN_GET_BUSINESS_DETAILS = '/api/v1/admin/user/business_by_id';
export const ADMIN_DELETE_USER = '/api/v1/admin/product/delete';
export const ADMIN_CHANGE_ROLE = '/api/v1/seller/user/change_role';
export const ADMIN_VERIFY_SELLER = '/api/v1/admin/user/verify_seller';
export const ADMIN_VERIFY_BUSINESS = '/api/v1/admin/user/verify_business';
export const GET_ADMIN_USERS_ANALYTICS = 'api/v1/admin/user/user_analytics';
export const GET_ADMIN_OVERVIEW = '/api/v1/admin/user/overview_analytics';

// Admin Deliveries
export const GET_ADMIN_DELIVERIES = '/api/v1/admin/delivery/view_delivery/all';
export const GET_ADMIN_DELIVERY = '/api/v1/admin/delivery/analytics';

// Admin roles
export const ADD_ROLE = '/api/v1/admin/role/addRole';
export const GET_ADMIN_ROLES = '/api/v1/admin/role/roles';
export const ADMIN_UPDATE_ROLE = '/api/v1/admin/role/update';
export const ADMIN_CHANGE_USER_ROLE = '/api/v1/admin/product/delete';
export const ADMIN_DELETE_ROLE = '/api/v1/admin/role/delete';
//Admin Logistics
export const ADMIN_GET_ALL_LOGISTICS = '/api/v1/admin/delivery_agent/all';
export const ADMIN_ADD_LOGISTICS = '/api/v1/admin/delivery_agent/add_new';
export const ADMIN_UPDATE_LOGISTICS = '/api/v1/admin/delivery_agent/update';
export const ADMIN_DELETE_LOGISTICS = '/api/v1/admin/delivery_agent/delete';
