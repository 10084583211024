import { Button, InputField, Spacer } from '../../../../components/atoms';
import { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TextEditor } from '../../../../components/molecules';
// import { APIUpload, Modal, TextEditor } from '../../../../components/molecules';
import { UploadIcon2 } from '../../../../assets/Icons';
// import { UploadIcon, UploadIcon2 } from '../../../../assets/Icons';
import {
  AddProductCON,
  FormCON,
  InputCon,
  extraStylesInput,
  AltInput,
  ImageUploadCON,
  TextEditorCON,
  CustomSelect,
  extraStylesButton
} from './style';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { COMMONTYPES } from '../../../../TYPES/event.types';
import { useQuery } from 'react-query';
import { useQueryClient } from 'react-query';
import {
  getAllcategories,
  getSingleProduct,
  getSubcategories,
  updateProduct
} from '../../../../network/services/goods';
import { useMutationHook } from '../../../../network/mutations/useMutationHook';
import { CustomBreadcrumb } from '../../../../components/atoms/Breadcrumb';
import { useParams, useNavigate } from 'react-router-dom';
import { ProductUpload } from '../../../../TYPES/api.body';
import ImageDropzone from '../../../../components/molecules/ImageDropzone';
import { ProductUploadResponse } from '../AddProduct';
import { getProfile } from '../../../../network/services/profile';

const INITIAL_STATE: ProductUpload = {
  subCategoryId: 1,
  productName: '',
  price: '',
  quantityAvailable: '',
  minimumOrderQuantity: '',
  bulkOrderPrice: '',
  barCode: '',
  variation: '',
  size: '',
  description: '',
  productImages: []
};

interface ImageState {
  image: File | string | null;
}

const INITIAL_IMAGE_STATES: ImageState[] = [
  { image: null },
  { image: null },
  { image: null },
  { image: null },
  { image: null }
];

export const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const productNameRef = useRef<HTMLInputElement>(null);
  const priceRef = useRef<HTMLInputElement>(null);
  const subCategoryIdRef = useRef<HTMLInputElement>(null);
  const quantityAvailableRef = useRef<HTMLInputElement>(null);
  const minimumOrderQuantityRef = useRef<HTMLInputElement>(null);
  const bulkOrderPriceRef = useRef<HTMLInputElement>(null);
  const barCodeRef = useRef<HTMLInputElement>(null);
  const variationRef = useRef<HTMLInputElement>(null);
  const sizeRef = useRef<HTMLInputElement>(null);
  // const productImage1Ref = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);

  const [imageStates, setImageStates] = useState(INITIAL_IMAGE_STATES);
  const inputRefs = useMemo(
    () =>
      Array(INITIAL_IMAGE_STATES.length)
        .fill(0)
        .map(i => createRef<HTMLInputElement>()),
    []
  );

  const [formData, setFormData] = useState(INITIAL_STATE);
  const { data: categoriesData } = useQuery('categories', getAllcategories);

  const { data: profileData } = useQuery(['get-profile'], getProfile);

  const profile = profileData?.data ?? [];

  const subCategories = profile?.subCategoriesMapList;
  useQuery(['product_details', { productId: id }], getSingleProduct, {
    enabled: !!id,
    staleTime: Infinity,
    onSuccess: _data => {
      const {
        productName,
        price,
        bulkOrderPrice,
        size,
        barCode,
        variation,
        quantityAvailable,
        minimumOrderQuantity,
        subCategoryId,
        description,
        images
      } = _data.data;

      productNameRef.current!.value = productName ?? '';
      priceRef.current!.value = price ?? '';
      bulkOrderPriceRef.current!.value = bulkOrderPrice ?? '';
      barCodeRef.current!.value = barCode ?? '';
      sizeRef.current!.value = size ?? '';
      variationRef.current!.value = variation ?? '';
      minimumOrderQuantityRef.current!.value = minimumOrderQuantity ?? '';
      quantityAvailableRef.current!.value = quantityAvailable ?? '';
      subCategoryIdRef.current!.value = subCategoryId ?? '';
      descriptionRef.current!.value = description ?? '';

      setFormData(formData => ({
        ...formData,
        productName,
        price,
        bulkOrderPrice,
        size,
        barCode,
        variation,
        quantityAvailable,
        minimumOrderQuantity,
        subCategoryId,
        description,
        productImages: [...images]
      }));

      images.forEach((imageStr: string, index: number) => {
        setImageStates(prevStates => {
          const updatedStates = [...prevStates];
          updatedStates[index] = { image: imageStr }; // Update state for each image
          return updatedStates;
        });
      });
    }
  });

  const handleFileRead = (e: COMMONTYPES['inputChange'], index: number) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageStates(prevStates => {
        const updatedStates = [...prevStates];
        updatedStates[index] = { image: file };
        return updatedStates;
      });

      setFormData(formData => ({
        ...formData,
        productImages: [...formData.productImages, file] as File[]
      }));
    }
  };

  const handleFileDelete = (index: number) => {
    setImageStates(prevStates => {
      const updatedStates = [...prevStates];
      updatedStates[index] = { image: null };
      return updatedStates;
    });

    // Remove the deleted image from the formData.productImages array
    setFormData(formData => ({
      ...formData,
      // @ts-ignore
      productImages: formData.productImages.filter((_: File | string, i: number) => i !== index) as File[]
    }));
  };

  // const productBannerRef = useRef<HTMLInputElement | null>(null);
  const queryClient = useQueryClient();

  const onSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, ...formData, [name]: value }));
  };

  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    e.stopPropagation();
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, ...formData, [name]: value }));
  };

  const onTextEditorChange = (value: string) => {
    setFormData(formData => ({ ...formData, ...formData, description: value }));
  };

  const selectClick = (e: COMMONTYPES['divClick']) => e.stopPropagation();

  const { mutateAsync, isLoading } = useMutationHook(updateProduct, 'PRODUCT_UPLOAD');
  const _updateProduct = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const payload = new FormData();

    // Append each field from formData to the payload as key-value pairs
    payload.append('subCategoryId', formData.subCategoryId.toString());
    payload.append('productName', formData.productName);
    payload.append('price', formData.price);
    payload.append('quantityAvailable', formData.quantityAvailable);
    payload.append('minimumOrderQuantity', formData.minimumOrderQuantity);
    payload.append('bulkOrderPrice', formData.bulkOrderPrice);
    payload.append('barCode', formData.barCode);
    payload.append('variation', formData.variation || '');
    payload.append('size', formData.size || '');
    payload.append('description', formData.description);

    // Append each product image to the payload
    formData.productImages.forEach((imageFile: any) => {
      payload.append('productImages', imageFile);
    });

    try {
      const data = (await mutateAsync({
        formData: payload,
        productId: String(id)
      })) as unknown as ProductUploadResponse;
      if (data.status === 'success') {
        queryClient.invalidateQueries('seller_products');
        navigate('/seller/products');
      }
    } catch (error: any) {}
  };

  const disabled = !formData.productImages || !formData.productName || !formData.price || !formData.description;

  return (
    <AddProductCON>
      <CustomBreadcrumb firstLabel="Products" secondLabel="View details" />
      <FormCON>
        <AltInput>
          <InputCon>
            <span>Product Name </span>
            <InputField
              type="text"
              inputRef={productNameRef}
              name="productName"
              placeholder="Name of Product"
              onChange={onInputChange}
              extraStyles={extraStylesInput}
              required={true}
              autoComplete="new-passowrd"
            />
          </InputCon>

          <InputCon>
            <span>Price</span>
            <InputField
              type="text"
              name="price"
              inputRef={priceRef}
              onChange={onInputChange}
              placeholder="Price of Product"
              extraStyles={extraStylesInput}
              required={true}
              // error={formError.password}

              autoComplete="new-passowrd"
            />
          </InputCon>
        </AltInput>

        <AltInput>
          <InputCon>
            <span>Sub category</span>
            <CustomSelect
              name="subCategoryId"
              value={formData.subCategoryId}
              ref={subCategoryIdRef}
              onChange={onSelectChange}
              onClick={selectClick}>
              {subCategories?.map((item: any, index: any) => (
                <MenuItem key={index} value={item.Id}>
                  {item.Subcategory}
                </MenuItem>
              ))}
            </CustomSelect>
          </InputCon>
        </AltInput>

        <AltInput>
          <InputCon>
            <span>Quantity Available</span>
            <InputField
              type="text"
              inputRef={quantityAvailableRef}
              name="quantityAvailable"
              onChange={onInputChange}
              placeholder="Quantity Available"
              extraStyles={extraStylesInput}
              required={true}
              autoComplete="new-passowrd"
            />
          </InputCon>

          <InputCon>
            <span>Minimum Order Quantity (MOQ)</span>
            <InputField
              type="text"
              name="minimumOrderQuantity"
              onChange={onInputChange}
              inputRef={minimumOrderQuantityRef}
              placeholder="Least order number"
              extraStyles={extraStylesInput}
              required={true}
              autoComplete="new-passowrd"
            />
          </InputCon>
        </AltInput>

        <AltInput>
          <InputCon>
            <span>Bulk Order Price at MOQ</span>
            <InputField
              type="text"
              inputRef={bulkOrderPriceRef}
              name="bulkOrderPrice"
              placeholder="Bulk order price when item hits MOQ"
              extraStyles={extraStylesInput}
              onChange={onInputChange}
              required={true}
              autoComplete="new-passowrd"
            />
          </InputCon>

          <InputCon>
            <span>Bar Code</span>
            <InputField
              type="text"
              inputRef={barCodeRef}
              name="barCode"
              placeholder="Bulk order price when item hits MOQ"
              extraStyles={extraStylesInput}
              required={true}
              onChange={onInputChange}
              autoComplete="new-passowrd"
            />
          </InputCon>
        </AltInput>

        <AltInput>
          <InputCon>
            <span>Variations</span>
            <InputField
              type="text"
              inputRef={variationRef}
              name="variation"
              placeholder="Product variations"
              extraStyles={extraStylesInput}
              onChange={onInputChange}
              required={true}
              autoComplete="new-passowrd"
            />
          </InputCon>

          <InputCon>
            <span>Size</span>
            <InputField
              type="text"
              name="size"
              inputRef={sizeRef}
              placeholder="Enter size"
              extraStyles={extraStylesInput}
              required={true}
              onChange={onInputChange}
              autoComplete="new-passowrd"
            />
          </InputCon>
        </AltInput>
        <Spacer height="1rem" />
        <TextEditorCON>
          <span>Description</span>
          <TextEditor
            inputRef={descriptionRef as any}
            value={descriptionRef.current?.value ?? ''}
            onChange={onTextEditorChange}
          />
        </TextEditorCON>

        <Spacer height="2rem" />

        <ImageUploadCON>
          <span>Product Images</span>
          <div className="images-con">
            <div className="primary-image-con">
              <ImageDropzone
                handleFileRead={e => handleFileRead(e, 0)}
                handleFileDelete={() => handleFileDelete(0)}
                inputRef={inputRefs[0]}
                image={imageStates[0].image}
              />
            </div>
            <div className="secondary-images-con">
              {imageStates.slice(1).map((imageState, index) => (
                <ImageDropzone
                  key={index}
                  handleFileRead={e => handleFileRead(e, index + 1)}
                  handleFileDelete={() => handleFileDelete(index + 1)}
                  inputRef={inputRefs[index + 1]}
                  image={imageState.image}
                  small
                />
              ))}
            </div>
          </div>
        </ImageUploadCON>
        <Spacer height="4rem" />
        <InputCon>
          <Button onClick={_updateProduct} isLoading={isLoading} disabled={disabled} extraStyles={extraStylesButton}>
            Save Changes
          </Button>
        </InputCon>
      </FormCON>
    </AddProductCON>
  );
};
